<template>
  <v-card
    :disabled="isPackageDisabled"
    class="full-height flex-column package package-card"
    outlined
    :style="[isPackageDisabled ? { borderColor: '#57AFEE' } : {}]">
    <v-card-title>
      <span class="package-title">{{ $t(singlePackage.name) }}</span>
      <v-spacer />
      <span v-if="isPackageDisabled">
        {{ $t('packages.active') }}
      </span>
    </v-card-title>
    <v-card-text class="full-height flex-column space-between">
      <p class="price">{{ getCurrency(singlePackage.currency) + singlePackage.price }}</p>

      <span v-if="singlePackage.monthlyPackagesMessage" class="package-info">{{
        $t(singlePackage.monthlyPackagesMessage)
      }}</span>
      <span v-if="singlePackage.annualPackagesMessage" class="package-info">{{
        $t(singlePackage.annualPackagesMessage)
      }}</span>
      <p class="package-info">{{ $t(singlePackage.mainMessage) }}</p>
      <v-list class="list-items">
        <v-list-item v-for="listItem in singlePackage.listItems" :key="listItem" class="list-item package-info">
          <v-list-item-icon class="mdi mdi-check-circle-outline check-icon"></v-list-item-icon>
          {{ $t(listItem) }}
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn class="primary" width="100%" @click="$emit('planSelected', singlePackage.id)">
          {{ $t('buttons.choose') }}
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SinglePackage',
  data: () => ({
    isPackageDisabled: false,
  }),
  props: ['singlePackage'],
  created() {
    this.isPackageDisabled = this.singlePackage.id == this.$store.state.auth.user.organisation.subscription_type_id;
  },
  methods: {
    getCurrency(currency) {
      switch (currency) {
        case 'EUR':
          return '€';
        case 'USD':
          return '$';
        case 'GBP':
          return '£';
        default:
          return '€';
      }
    },
  },
  watch: {
    '$store.state.auth.user.organisation.subscription_type_id': {
      handler() {
        this.isPackageDisabled = this.singlePackage.id == this.$store.state.auth.user.organisation.subscription_type_id;
      },
      deep: false,
    },
  },
};
</script>

<style>
.package-card {
  box-shadow: 8px 8px 8px -8px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}
.package-title {
  display: inline-block;
  background-color: rgba(47, 85, 212, 0.1);
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  line-height: 1.4;
  color: #54568c;
  font-size: 1rem;
  font-weight: 500;
}

.price {
  font-weight: 700;
  font-size: 1.7rem;
  color: #444444;
  margin-top: 1rem;
}

.package-info {
  font-size: 1rem;
  color: #333333;
}

.full-height {
  height: 100%;
}

.list-items .v-list.item {
  font-size: 1rem !important;
  color: #606060;
}

.list-item {
  min-height: unset;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.check-icon {
  font-size: 20px;
  color: var(--primary);
  margin: 8px !important;
}
</style>
